import { Controller } from '@hotwired/stimulus';
import noUiSlider from 'nouislider';
import numeral from 'numeral';
import PricingTiers from '../pricing_tiers';

export default class extends Controller {

  static targets = ['priceOuter', 'monthlyPrice', 'monthlyPriceDiscounted', 'weeklyPrice', 'weeklyPriceDiscounted', 'accountingMethod', 'expensesSlider', 'expensesAmount', 'expensesLabel'];

  connect() {
    let i, tier;
    const slider = document.getElementById('expenses-slider');

    const sliderRange = {};
    const gap = (100 / (PricingTiers.length - 1)) * 1.0;
    for (i = 0; i < PricingTiers.length; i++) {
      tier = PricingTiers[i];
      var tierLevel = gap * i;
      tierLevel =
        tierLevel === 0 ?
          'min'
        : tierLevel >= 100 ?
          'max'
        :
          `${tierLevel}%`;
      sliderRange[tierLevel] = PricingTiers[i].monthly_expenses;
    }

    // Create the slider
    noUiSlider.create(slider, {
      behaviour: 'tap-drag',
      start: 1000,
      format: {
        to(value) {
          return parseInt(value);
        },
        from(value) {
          return parseInt(value);
        }
      },
      connect: [true, false],
      range: sliderRange
    }
    );

    // Run after release of slider handle
    slider.noUiSlider.on('start', (values, handle) => {
      return $('.noUi-touch-area').addClass('hide-tooltip');
    });

    slider.noUiSlider.on('change', (values, handle) => {
      const value = parseInt(values[handle]);
      const selectedTier = this.getTier(values);
      slider.noUiSlider.set(selectedTier.monthly_expenses);
      return this.setHandleBg(selectedTier);
    });

    // Run when dragging slider handle
    slider.noUiSlider.on('update', (values, handle) => {
      const value = parseInt(values[handle]);
      const selectedTier = this.getTier(value);

      $(this.expensesSliderTarget).attr('data-value', value);

      // Color notches
      $(`.slider-notch:nth-child(-n + ${selectedTier.index})`).addClass('colored');
      $(`.slider-notch:nth-child(n + ${selectedTier.index + 1})`).removeClass('colored');

      // Set expense label/amount text
      let expenseAmountText = numeral(value).format('$0,0');
      if (parseInt(value) > PricingTiers[PricingTiers.length - 2].monthly_expenses) {
        $(this.expensesLabelTarget).find('span:first-child').text('Over');
        expenseAmountText = numeral(PricingTiers[PricingTiers.length - 2].monthly_expenses).format('$0,0');
      } else {
        $(this.expensesLabelTarget).find('span:first-child').text('Up to');
      }

      // Update expenses amount text
      $(this.expensesAmountTarget).text(expenseAmountText);
      $(this.expensesAmountTarget).attr('data-tier', selectedTier.monthly_expenses);

      this.setHandleBg(selectedTier);
      return this.calculatePrice();
    });

    // Append slider notches
    const $notchParent = $('<ul id="slider-notches"></ul>');

    for (i = 0; i < PricingTiers.length; i++) {
      tier = PricingTiers[i];
      var notch = $('<li class="slider-notch"></li>');
      notch.attr('data-value', tier.monthly_expenses);
      $notchParent.append(notch);
    }

    $('.noUi-base').append($notchParent);

    return slider.noUiSlider.set(20000);
  }

  calculatePrice() {
    const value = parseInt($(this.expensesSliderTarget).attr('data-value'));
    const accountingMethod = $(this.accountingMethodTarget).attr('data-value');
    const monthlyExpenses = parseInt($(this.expensesAmountTarget).attr('data-tier'));
    const selectedTier = PricingTiers.filter(tier => tier.monthly_expenses === monthlyExpenses)[0];

    if (value > PricingTiers[PricingTiers.length - 2].monthly_expenses) {
      return $(this.priceOuterTargets).attr('data-contact-visible', true);
    } else {
      $(this.priceOuterTargets).attr('data-contact-visible', false);

      let monthlyPrice = undefined;
      let weeklyPrice = undefined;

      weeklyPrice = selectedTier[accountingMethod].weekly;
      monthlyPrice = selectedTier[accountingMethod].monthly;
      const billingTermText = 'monthly';

      $(this.monthlyPriceTarget).text(numeral(monthlyPrice).format('$0,0'));
      $(this.weeklyPriceTarget).text(numeral(weeklyPrice).format('$0,0'));
      $(this.billingTermTargets).each(function() {
        return $(this).text(billingTermText);
      });

      if (!this.hasMonthlyPriceDiscountedTarget || !this.hasWeeklyPriceDiscountedTarget) { return; }

      $(this.monthlyPriceDiscountedTarget).text(numeral(monthlyPrice - 50).format('$0,0'));
      return $(this.weeklyPriceDiscountedTarget).text(numeral(weeklyPrice - 50).format('$0,0'));
    }
  }

  setToggle(event) {
    const target = $(event.target);
    const root = target.closest('.toggle');
    const handle = root.find('.toggle-handle');
    const value = target.attr('data-value');

    const newPosition =
      target.closest('.toggle-label').length ?
        target.closest('.toggle-label').attr('data-position')
      :
        handle.attr('data-position') === 'left' ?
          'right'
        :
          'left';

    // Deactivate all labels
    root.find('.toggle-label').attr('data-active', false);

    // Set position of toggle handle
    handle.attr('data-position', newPosition);

    // Set active label
    root.find(`.toggle-label[data-position='${newPosition}']`).attr('data-active', true);

    // Set value on root
    const newValue = root.find(".toggle-label[data-active='true']").attr('data-value');
    root.attr('data-value', newValue);

    return this.calculatePrice();
  }

  getTier(value) {
    let selectedTier = {};
    for (var i = 0; i < PricingTiers.length; i++) {
      var tier = PricingTiers[i];
      var nextTier = PricingTiers[i + 1];
      if (i === (PricingTiers.length - 1)) {
        selectedTier = {
          index: i + 1,
          value: tier.monthly_expenses,
          amount: tier.amount
        };
      } else if ((value >= tier.monthly_expenses) && (value < nextTier.monthly_expenses)) {
        selectedTier =
          (() => {
          if ((value - tier.monthly_expenses) > (nextTier.monthly_expenses - value)) {
            nextTier.index = i + 2;
            return nextTier;
          } else {
            tier.index = i + 1;
            return tier;
          }
        })();
        break;
      }
    }
    return selectedTier;
  }

  setHandleBg(selectedTier) {
    const currentBgPos = $('.noUi-handle').attr('data-bg-position');
    const newBgPos =
      selectedTier.index < 3 ?
        'left'
      : (selectedTier.index >= 3) && (selectedTier.index < 5) ?
        'center'
      :
        'right';

    if (currentBgPos !== newBgPos) {
      return $('.noUi-handle').attr('data-bg-position', newBgPos);
    }
  }
}
