let PricingTiers;
export default PricingTiers = [
  {
    monthly_expenses: 20000,
    cash: {
      monthly: 399,
      weekly: 549
    },
    accrual: {
      monthly: 549,
      weekly: 749
    }
  },
  {
    monthly_expenses: 35000,
    cash: {
      monthly: 499,
      weekly: 649
    },
    accrual: {
      monthly: 649,
      weekly: 849
    }
  },
  {
    monthly_expenses: 50000,
    cash: {
      monthly: 599,
      weekly: 749
    },
    accrual: {
      monthly: 749,
      weekly: 949
    }
  },
  {
    monthly_expenses: 65000,
    cash: {
      monthly: 699,
      weekly: 849
    },
    accrual: {
      monthly: 849,
      weekly: 1049
    }
  },
  {
    monthly_expenses: 100000,
    cash: {
      monthly: 899,
      weekly: 1049
    },
    accrual: {
      monthly: 1049,
      weekly: 1299
    }
  },
  {
    monthly_expenses: 105000,
    cash: {
      monthly: 'contact',
      weekly: 'contact'
    },
    accrual: {
      monthly: 'contact',
      weekly: 'contact'
    }
  }
];
